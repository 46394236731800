<lib-panel *ngIf="customerRecoveryData$ | async; else sectionLoadingLib">
  <header>{{ item.label }}</header>
  <content>
    <div
      *ngIf="damageData$ | async as additionalLineItem; else sectionLoadingLib">
      @if (additionalLineItem) {
        @for (lineItem of additionalLineItem; track index; let index = $index) {
          @if (lineItem.items) {
            <div
              class="line-item-main"
              [ngStyle]="{ 'margin-top': index != 0 ? '-16px' : '' }">
              <div class="w-90">
                <app-lib-form
                  [items]="lineItem.items"
                  (isValidChange)="
                    formValidityChanged($event, index)
                  "></app-lib-form>
              </div>
              <div
                class="btn-div w-10"
                [ngStyle]="{
                  'padding-top': additionalLineItem.length == 1 ? '24px' : ''
                }">
                <mc-button
                  *ngIf="index > 0"
                  appearance="neutral"
                  fit="medium"
                  icon="trash"
                  [disabled]="disable"
                  (click)="deleteDamageItem(lineItem, index)"
                  label=""></mc-button>

                <mc-button
                  *ngIf="index == additionalLineItem.length - 1"
                  appearance="neutral"
                  fit="medium"
                  icon="plus"
                  [disabled]="disable"
                  (click)="addDamageItem()"
                  label=""></mc-button>
              </div>
            </div>
          }
        }
      }
    </div>
    <div class="header">
      <div class="header-text">
        Total ({{ currencyCode }}) {{ totalAmount }}
      </div>
      <div *ngIf="currencyCode != 'USD'" class="header-text">
        Total (USD) {{ totalAmountUSD }}
      </div>
    </div>
  </content>
</lib-panel>
<ng-template #sectionLoadingLib>
  <lib-section-skeleton-loader></lib-section-skeleton-loader>
</ng-template>
