import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  LibFormComponent,
  PanelComponent,
  SectionSkeletonLoaderComponent,
} from '@maersk-global/angular-shared-library';
import { TemplateModel } from '@maersk-global/angular-shared-library/lib/models/template-model';
import { AppWorkflowContainerMovesComponent } from '../app-workflow-container-moves/app-workflow-container-moves.component';
import { GlobalService } from '../../../global-service';
import { tap } from 'rxjs';
import { CustomerRecoveryCaseDto } from '../../../common/models/customerRecoveryCaseDto';
import { FIELD_NAME } from '../../../common/constants/app.constants';
import { RepairLineItem } from '../../../common/models/repairLineItem';
import { caseCpInformation } from '../../../common/models/caseCpInformation';

@Component({
  selector: 'app-workflow-cp-info',
  standalone: true,
  imports: [
    LibFormComponent,
    CommonModule,
    AppWorkflowContainerMovesComponent,
    PanelComponent,
    SectionSkeletonLoaderComponent,
  ],
  templateUrl: './app-workflow-cp-info.component.html',
  styleUrl: './app-workflow-cp-info.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppWorkflowCpInfoComponent implements OnInit {
  @Input() item!: TemplateModel;
  @Input() disable?: boolean | false;
  items: TemplateModel[] = [];
  manualEstimates: RepairLineItem[] | undefined;
  cpDetails: caseCpInformation | undefined;

  cpInformation$ = this.globalService.cpInformation$.pipe(
    tap((cpInformation) => {
      this.bindValueToItems(cpInformation);
    })
  );

  recoveryData: CustomerRecoveryCaseDto | undefined;

  constructor(private globalService: GlobalService) {}

  ngOnInit() {
    this.items = this.item.items as TemplateModel[];
  }

  bindValueToItems(cpInformation: caseCpInformation | undefined) {
    this.items.forEach((item: TemplateModel) => {
      item.disabled = this.disable;

      if (item.name == FIELD_NAME.COVERAGE_AMOUNT) {
        item.value = '$' + (cpInformation?.cpCoverageAmountUSD ?? 0);
      } else if (item.name == FIELD_NAME.CP_PLAN) {
        item.value = cpInformation?.cpPlan ?? '-';
      } else if (item.name == FIELD_NAME.ABOVE_RECOVERABLE_COST) {
        item.value =
          '$' + this.formatDecimal(cpInformation?.aboveCoverageCostUSD);
      } else if (item.name == FIELD_NAME.RECOVERABLE_COST_USD) {
        item.value =
          '$' + this.formatDecimal(cpInformation?.recoverableCostUSD);
      }
    });
  }

  private formatDecimal(value: number | undefined): number {
    if (!value) return 0;
    return Math.round(value * 100) / 100;
  }
}
