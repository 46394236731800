<div class="workflow-tab" *ngIf="customerRecoveryData$ | async as Recovery">
  <mc-tab-bar
    *ngIf="loadTab"
    (tabchange)="onTabChange($event)"
    [currentindex]="currentTabIndex">
    @for (tab of currentStageItem; track tab; let index = $index) {
      <mc-tab slot="tab" label="{{ tab.label }}"></mc-tab>
    }
  </mc-tab-bar>
  <ng-container *ngIf="reopenCase$ | async">
    <mc-notification
      heading="Case Closed"
      verticalalign="middle"
      actionsposition="right"
      appearance="info"
      body="{{ closeReason }}"
      icon="exclamation-octagon">
      <span slot="actions">
        <mc-button
          fit="medium"
          appearance="neutral"
          label="Re-open Case"
          (click)="reOpeningCase()"></mc-button>
      </span>
    </mc-notification>
  </ng-container>
  <ng-container *ngIf="currentStageId$ | async">
    <ng-container *ngIf="{ value: disableForm$ | async } as disable">
      <ng-container
        *ngIf="{
          value: shouldShowLiabilityDetails$ | async
        } as shouldShowLiabilityDetails">
        <ng-container
          *ngIf="{
            value: shouldShowIssueInvoiceScreen$ | async
          } as shouldShowIssueInvoiceScreen">
          <div class="panel-wrapper">
            @if (tabItems) {
              @for (subTab of tabItems; track subTab) {
                <ng-container>
                  @if (subTab.name) {
                    <ng-container [ngSwitch]="subTab.name">
                      <ng-container *ngSwitchCase="'overview'">
                        @if (subTab.items) {
                          <app-workflow-overview
                            [disable]="disable.value ?? false"
                            [item]="subTab"></app-workflow-overview>
                        }
                      </ng-container>
                      <ng-container *ngSwitchCase="'attachments'">
                        @if (subTab.items) {
                          <app-app-workflow-attachment
                            [disable]="disable.value ?? false"
                            [item]="subTab"></app-app-workflow-attachment>
                        }
                      </ng-container>
                      <ng-container *ngSwitchCase="'containerInformation'">
                        @if (subTab.items) {
                          <app-workflow-container-info
                            [item]="subTab"></app-workflow-container-info>
                        }
                      </ng-container>
                      <ng-container *ngSwitchCase="'bookingInformation'">
                        @if (
                          (subTab.items && shouldShowLiabilityDetails.value) ||
                          currentStageId != LIABILITY_STAGE_ID
                        ) {
                          <app-workflow-booking-info
                            [disable]="disable.value ?? false"
                            [item]="subTab"></app-workflow-booking-info>
                        }
                      </ng-container>
                      <ng-container *ngSwitchCase="'cpInformation'">
                        <app-workflow-cp-info
                          [disable]="disable.value ?? false"
                          [item]="subTab"></app-workflow-cp-info>
                      </ng-container>
                      <ng-container *ngSwitchCase="'cargoDetails'">
                        @if (
                          (subTab.items && shouldShowLiabilityDetails.value) ||
                          currentStageId != LIABILITY_STAGE_ID
                        ) {
                          <app-workflow-cargo-details
                            [containerNumber]="containerNumber"
                            [disable]="disable"
                            [item]="subTab"></app-workflow-cargo-details>
                        }
                      </ng-container>
                      <ng-container *ngSwitchCase="'liabilityDetails'">
                        @if (subTab.items) {
                          <app-workflow-liability-details
                            [workOrders]="workOrders"
                            [item]="subTab"></app-workflow-liability-details>
                        }
                      </ng-container>
                      <ng-container
                        *ngSwitchCase="'liabilityDetailsManualEstimates'">
                        @if (subTab.items) {
                          <app-workflow-liability-details-manual-estimates
                            [item]="
                              subTab
                            "></app-workflow-liability-details-manual-estimates>
                        }
                      </ng-container>
                      <ng-container *ngSwitchCase="'activityLog'">
                        @if (subTab.items) {
                          <app-workflow-activity-log
                            [item]="subTab"></app-workflow-activity-log>
                        }
                      </ng-container>
                      <ng-container *ngSwitchCase="'work-order-details'">
                        @if (subTab.items) {
                          <app-workflow-work-orders
                            [item]="subTab"
                            (workOrdersLoaded)="
                              onWorkOrdersLoaded($event)
                            "></app-workflow-work-orders>
                        }
                      </ng-container>
                      <ng-container *ngSwitchCase="'manual-estimates'">
                        @if (subTab.items) {
                          <app-workflow-manual-estimates
                            #manualEstimates
                            [disable]="disable.value ?? false"
                            [item]="subTab"></app-workflow-manual-estimates>
                        }
                      </ng-container>
                      <ng-container *ngSwitchCase="'damage-Details'">
                        @if (subTab.items) {
                          <app-workflow-damage-details
                            #damageDetails
                            [disable]="disable.value ?? false"
                            [item]="subTab"></app-workflow-damage-details>
                        }
                      </ng-container>
                      <ng-container *ngSwitchCase="'liable-party-details'">
                        @if (subTab.items && shouldShowLiabilityDetails.value) {
                          <app-workflow-liable-party-details
                            #party
                            [item]="subTab"
                            (onLiabilityPartySelected)="
                              onLiabilityPartySelected($event)
                            "></app-workflow-liable-party-details>
                        }
                      </ng-container>
                      <ng-container *ngSwitchCase="'liability-letter'">
                        @if (
                          liabilityEmailContent &&
                          shouldShowLiabilityDetails.value
                        ) {
                          <app-liability-letter
                            #letterComponent
                            [item]="subTab"
                            [email]="
                              liabilityEmailContent
                            "></app-liability-letter>
                        }
                      </ng-container>
                      <ng-container *ngSwitchCase="'liability-letters-log'">
                        @if (!shouldShowLiabilityDetails.value) {
                          <app-liability-letters-log></app-liability-letters-log>
                        }
                      </ng-container>
                      <ng-container *ngSwitchCase="'invoice-summary'">
                        @if (shouldShowIssueInvoiceScreen.value) {
                          <invoice-summary [item]="subTab"></invoice-summary>
                        }
                      </ng-container>
                      <ng-container *ngSwitchCase="'invoice-letter'">
                        @if (shouldShowIssueInvoiceScreen.value) {
                          <invoice-letter #invoice></invoice-letter>
                        }
                      </ng-container>
                      <ng-container *ngSwitchCase="'invoice-letters-log'">
                        @if (!shouldShowIssueInvoiceScreen.value) {
                          <app-invoice-letters-log></app-invoice-letters-log>
                        }
                      </ng-container>
                      <ng-container *ngSwitchCase="'workflow-completed'">
                        <app-workflow-completed></app-workflow-completed>
                      </ng-container>
                    </ng-container>
                  }
                </ng-container>
              }
            }
          </div>
        </ng-container>
        <div class="actions" *ngIf="currentTabIndex == 0">
          <ng-container *ngIf="{ value: anchorDetails$ | async } as anchor">
            <mc-button
              appearance="primary"
              [loading]="!anchor.value || isSaveOperationInProgress"
              fit="medium"
              label="Next"
              [disabled]="!isNextButtonEnabled || isSaveOperationInProgress"
              [hidden]="isNextButtonHidden"
              (click)="onNextClick()"></mc-button>
            <mc-button
              *ngIf="currentStageId !== 1"
              fit="medium"
              appearance="neutral"
              label="Back"
              [disabled]="!anchor.value"
              (click)="onBackClick()"></mc-button>
          </ng-container>

          <mc-button
            *ngIf="enableCloseBtn$ | async"
            fit="medium"
            appearance="neutral"
            label="Close Case"
            (click)="closeCase = true"></mc-button>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="closeCase" [formGroup]="closeCaseForm">
    <mc-modal
      [open]="closeCase"
      escapecloseactiondisabled
      backdropcloseactiondisabled
      heading="Close Case"
      [height]="
        closeCaseForm.get('closeReason')?.value == OTHERS ? '350px' : '240px'
      "
      dimension="small"
      fit="small"
      (closed)="closeCase = false"
      (closing)="closeCase = false">
      <mc-select
        ngDefaultControl
        label="Reason for closing case"
        placeholder="Select a reason"
        formControlName="closeReason"
        optionswidth="trigger">
        <ng-container *ngFor="let reason of cancelReasons">
          <mc-option [value]="reason">{{ reason }}</mc-option>
        </ng-container>
      </mc-select>
      <div
        *ngIf="closeCaseForm.get('closeReason')?.value == OTHERS"
        style="padding-top: 2px">
        <mc-textarea
          ngDefaultControl
          label="Comments"
          formControlName="comments"
          placeholder="Enter a reason for closing the case"></mc-textarea>
      </div>

      <mc-button
        focusstartanchor
        slot="primaryAction"
        appearance="primary"
        (click)="closingCase()">
        Submit
      </mc-button>
      <mc-button
        focusendanchor
        slot="secondaryAction"
        appearance="neutral"
        variant="outlined"
        (click)="closeCase = false">
        Cancel
      </mc-button>
    </mc-modal>
  </ng-container>
</div>
