<form [formGroup]="newCaseForm">
  <mc-modal
    [open]="true"
    escapecloseactiondisabled
    backdropcloseactiondisabled
    dimension="large"
    fit="medium"
    (closed)="onModelClose($event)">
    <span slot="heading" class="header-body">
      <div class="header">New Case</div>
      <div class="sub-header">
        Enter the container number to view existing work orders and recovery
        cases
      </div>
    </span>

    <div class="create-case-wrapper">
      <ng-container *ngTemplateOutlet="containerNumberSearchBox"></ng-container>
      <ng-container *ngTemplateOutlet="createCaseWithWorkOrder"></ng-container>
      <ng-container
        *ngTemplateOutlet="createCaseWithoutWorkOrder"></ng-container>
    </div>

    <mc-button
      focusstartanchor
      slot="primaryAction"
      appearance="primary"
      [disabled]="disableCaseCreation"
      [loading]="cpDetailsLoading"
      (click)="createCase()">
      Create Case
    </mc-button>

    <mc-button
      focusendanchor
      slot="secondaryAction"
      appearance="neutral"
      variant="outlined"
      (click)="onModelClose($event)">
      Cancel
    </mc-button>
  </mc-modal>

  <ng-template #containerNumberSearchBox>
    <div class="container-search">
      <mc-input
        ngDefaultControl
        [invalid]="
          !!containerNumberInput.errors &&
          (containerNumberInput.touched ||
            containerNumberInput.errors['isNotAlphanumeric'])
        "
        label="Container Number"
        placeholder="Enter Container Number"
        [maxlength]="11"
        formControlName="containerNumberInput"></mc-input>
      <mc-button
        appearance="primary"
        [disabled]="containerNumberInput.errors"
        (click)="searchContainerNumber()">
        Search
      </mc-button>
    </div>
    <mc-error
      *ngIf="
        !!containerNumberInput.errors &&
        containerNumberInput.errors?.['isNotAlphanumeric']
      "
      invalid
      errormessage="Special characters are not allowed"></mc-error>
    <mc-error
      *ngIf="
        containerNumberInput.touched &&
        !!containerNumberInput.errors &&
        containerNumberInput.errors['containerNumberInvalid'] &&
        !containerNumberInput.errors['isNotAlphanumeric']
      "
      invalid
      errormessage="Please enter a valid container number"></mc-error>
  </ng-template>

  <ng-template #createCaseWithWorkOrder>
    <ng-container
      *ngIf="workOrderGridData$ | async as workOrderGridData; else pageLoad">
      <lib-table-skeleton-loader
        *ngIf="areWorkOrdersLoading"></lib-table-skeleton-loader>
      <ng-container *ngIf="!areWorkOrdersLoading">
        <div class="work-order-details">
          <span>Use existing work order or recovery case</span>
          <span>
            Time period : {{ searchFromDateUTC | date: 'dd MMM yyyy' }} -
            {{ searchToDateUTC | date: 'dd MMM yyyy' }}
          </span>
        </div>
        <app-no-data
          *ngIf="workOrderGridData.length === 0"
          [height]="{ size: 300, unit: 'px' }">
          No work orders found
          <br />
          Create a recovery case without work order
        </app-no-data>
        <app-grid
          *ngIf="workOrderGridData.length > 0"
          [schema]="woGridSchema"
          [data]="workOrderGridData"
          [showPanel]="false"
          [showRowSelector]="true"
          [showPagination]="false"
          [rowSelectorType]="'radio'"
          [disabled]="caseCreationWithoutWorkOrder"
          (rowSelectionChange)="woSelectionChanged($event)"></app-grid>
        <mc-checkbox
          *ngIf="workOrderGridData.length !== 0"
          label="Create a recovery case without work order"
          name="CaseCreationWithoutWorkOrder"
          [disabled]="containerNumberInput.errors"
          (change)="createCaseWithoutWorkOrderCheckBoxChange()"></mc-checkbox>
      </ng-container>
    </ng-container>
  </ng-template>

  <ng-template #pageLoad>
    <app-no-data
      *ngIf="!areWorkOrdersLoading"
      [height]="{ size: 300, unit: 'px' }">
      Work orders and cases for the container will be shown here
    </app-no-data>
    <lib-table-skeleton-loader
      *ngIf="areWorkOrdersLoading"></lib-table-skeleton-loader>
  </ng-template>

  <ng-template #createCaseWithoutWorkOrder>
    <lib-panel [hideHeader]="true" *ngIf="caseCreationWithoutWorkOrder">
      <content>
        <div class="container-moves-section-wrapper">
          <div class="incident-date-responsible-country">
            <div>
              <mc-input-date
                ngDefaultControl
                formControlName="incidentDate"
                startofweek="1"
                format="DD-MM-YYYY"
                locale="en-IN"
                nextlabel="Next month"
                previouslabel="Previous month"
                placeholder="DD-MM-YYYY"
                width="232px"
                [yearcap]="20"
                label="Incident Date"
                name="incidentDate"
                max="{{ currentDate }}"
                (inputdateselected)="searchContainerMovesBasedOnIncidentDate()"
                (blur)="searchContainerMovesBasedOnIncidentDate()"
                [invalid]="
                  incidentDate.touched && !!incidentDate.errors
                "></mc-input-date>
              <mc-error
                *ngIf="incidentDate.touched && !!incidentDate.errors"
                invalid
                errormessage="Please enter a valid incident date"></mc-error>
            </div>
            <div>
              <mc-typeahead
                ngDefaultControl
                showlistonfocus
                label="Repair Country"
                placeholder="Type to search the options"
                formControlName="repairCountry"
                name="repairCountry"
                autocomplete="new-repairCountry"
                maxoptions="10000"
                #typeAheadControl
                [data]="countries$ | async"
                [invalid]="repairCountry.touched && !!repairCountry.errors"
                (blur)="validateRepairCountry($event)"></mc-typeahead>
              <mc-error
                *ngIf="repairCountry.touched && !!repairCountry.errors"
                invalid
                errormessage="Please select valid repair country"></mc-error>
            </div>
          </div>
          <lib-panel>
            <header>Container Moves</header>
            <content>
              <div class="container-moves-section-wrapper">
                <div class="container-moves-section">
                  <mc-input-date
                    ngDefaultControl
                    formControlName="containerMovesFromDate"
                    startofweek="1"
                    format="DD-MM-YYYY"
                    locale="en-IN"
                    nextlabel="Next month"
                    previouslabel="Previous month"
                    placeholder="DD-MM-YYYY"
                    width="232px"
                    fit="small"
                    [yearcap]="20"
                    label="From"
                    name="containerMovesFromDate"
                    max="{{ movesToDate }}"
                    (input)="resetContainerMovesGrid()"
                    (blur)="validateAndSetFromDate()"
                    [invalid]="
                      containerMovesFromDate.touched &&
                      !!containerMovesFromDate.errors
                    "></mc-input-date>

                  <mc-input-date
                    ngDefaultControl
                    formControlName="containerMovesToDate"
                    startofweek="1"
                    format="DD-MM-YYYY"
                    locale="en-IN"
                    nextlabel="Next month"
                    previouslabel="Previous month"
                    placeholder="DD-MM-YYYY"
                    width="232px"
                    fit="small"
                    [yearcap]="20"
                    label="To"
                    name="containerMovesToDate"
                    min="{{ movesFromDate }}"
                    max="{{ currentDate }}"
                    (input)="resetContainerMovesGrid()"
                    (blur)="validateAndSetToDate()"
                    [invalid]="
                      containerMovesToDate.touched &&
                      !!containerMovesToDate.errors
                    "></mc-input-date>

                  <mc-button
                    #containerMoveSection
                    fit="small"
                    appearance="primary"
                    [disabled]="
                      !!containerMovesToDate.errors ||
                      !!containerMovesFromDate.errors
                    "
                    (click)="showContainerMoves = true">
                    Get moves
                  </mc-button>

                  <mc-button
                    fit="small"
                    appearance="neutral"
                    variant="outlined">
                    Cancel
                  </mc-button>
                </div>
                <div *ngIf="showContainerMoves">
                  <mc-label>
                    To select the booking number, select GATE-OUT DEL move
                  </mc-label>
                  <app-workflow-container-moves
                    [item]="containerMovesItem"
                    [fromDate]="containerMovesFromDate.value"
                    [toDate]="containerMovesToDate.value"
                    [containerNumber]="searchedContainerNumber"
                    [disable]="false"
                    (onRowSelected)="onContainerMoveSelected($event)"
                    (onDataLoad)="
                      setContainerProperties($event)
                    "></app-workflow-container-moves>
                </div>
              </div>
            </content>
          </lib-panel>
        </div>
      </content>
    </lib-panel>
  </ng-template>
</form>
