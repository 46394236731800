<div class="workflow-stepper" *ngIf="currentStageId$ | async">
  <div class="step-indicator">
    <mc-step-indicator *ngIf="workflowSteps">
      @for (step of workflowSteps; track step; let index = $index) {
        <mc-step-indicator-item
          fit="medium"
          (click)="showStage(index)"
          [label]="step.name"
          [ngClass]="step.className"
          [state]="step.state">
          <span slot="icon">
            <mc-icon
              *ngIf="step.className !== 'stage-current'"
              class="step-icon"
              [icon]="step.icon"></mc-icon>
            <img
              class="step-icon-svg"
              *ngIf="step.className === 'stage-current'"
              src="../../../../assets/images/progress-bar-circle-icon.svg"
              alt="" />
          </span>
        </mc-step-indicator-item>
      }
    </mc-step-indicator>
  </div>
</div>
