import { CommonModule, DatePipe } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  Input,
  SimpleChanges,
} from '@angular/core';
import {
  LibFormComponent,
  PanelComponent,
} from '@maersk-global/angular-shared-library';
import { TemplateModel } from '@maersk-global/angular-shared-library/lib/models/template-model';
import { lastValueFrom, map, Observable, tap } from 'rxjs';
import { DropDownOption } from '@maersk-global/angular-shared-library/lib/models/drop-down';
import { CurrencyAndExchangeRateDto } from '../../../common/models/currencyAndExchangeRateDto';
import { CustomerRecoveryCaseDto } from '../../../common/models/customerRecoveryCaseDto';
import { GlobalService } from '../../../global-service';
import { CommonService } from '../../../common/services/customer-recovery/common.service';
const DATE_OF_INCIDENT = 'dateOfIncident';
const POD_LOCAL_CURRENCY: string = 'podLocalCurrency';
const EXCHANGE_RATE: string = 'exchangerate';
const POD_COUNTRY: string = 'podCountryName';
const POD_CLUSTER: string = 'podCountryClusterName';
const OPERATOR_CODE: string = 'operatorCode';
@Component({
  selector: 'app-workflow-overview',
  standalone: true,
  imports: [CommonModule, LibFormComponent, PanelComponent],
  templateUrl: './app-workflow-overview.component.html',
  styleUrl: './app-workflow-overview.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [DatePipe],
})
export class AppWorkflowOverviewComponent {
  @Input() item!: TemplateModel;
  @Input() disable?: boolean | false;

  items: TemplateModel[] = [];
  currencyAndExchangeRate!: CurrencyAndExchangeRateDto[];
  caseDetail?: CustomerRecoveryCaseDto;
  apiVersion: string = '1.0';
  constructor(
    private datePipe: DatePipe,
    private globalService: GlobalService,
    private _commonService: CommonService
  ) {}

  customerRecoveryData$ = this.globalService.customerRecoveryData$.pipe(
    tap((recoveryData) => {
      this.caseDetail = recoveryData;
      this.loadOverviewData();
    })
  );

  loadOverviewData() {
    this.items = this.item.items as TemplateModel[];
    this.item?.items?.forEach(async (overview: TemplateModel) => {
      overview.disabled = overview.disabled ?? this.disable;
      if (overview.type == 'select' && overview.name) {
        overview.options = await this.getFilterOptionsByColumn(overview.name);
        if (overview.value && overview.name == POD_LOCAL_CURRENCY) {
          this.bindExchangesRateValue(overview.value);
        }
      }
    });
    this.bindOverViewData();
  }

  ngOnChange(changes: SimpleChanges) {
    if (changes['disable']) {
      this.item?.items?.forEach(async (i: TemplateModel) => {
        this.item?.items?.forEach(async (i: TemplateModel) => {
          i.disabled = this.disable;
        });
      });
    }
  }

  bindOverViewData() {
    if (this.item.loaded) return;
    const keyValueCustomerDetail = this.caseDetail as unknown as {
      [key: string]: unknown;
    };

    this.items.forEach((item: TemplateModel) => {
      if (item.name == POD_LOCAL_CURRENCY) {
        item.value =
          this.caseDetail?.caseCurrency ??
          this.caseDetail?.podLocalCurrency ??
          'USD';
        item.onValueChanged = (value) => {
          this.bindExchangesRateValue.call(this, value);
        };
      } else if (item.name == DATE_OF_INCIDENT && item.value) {
        item.value = this.datePipe.transform(
          new Date(keyValueCustomerDetail[item.name] as string),
          'dd-MM-yyyy'
        );
      } else {
        item.value = keyValueCustomerDetail[item.name as string];
      }
    });
    this.item.loaded = true;
  }

  bindExchangesRateValue(value: string) {
    //Checking if the dataset is populated
    if (!this.currencyAndExchangeRate) {
      return;
    }

    const exchangeRateObj = this.currencyAndExchangeRate.find(
      (currency) => currency.quotationCurrency == value
    );
    this.items.filter((item: TemplateModel) => {
      if (item.name == EXCHANGE_RATE) {
        const transformDate = exchangeRateObj?.quotationDateTime
          ? this.datePipe.transform(
              new Date(exchangeRateObj?.quotationDateTime),
              'd MMM y'
            )
          : '';
        item.value = `${exchangeRateObj?.exchangeRatePerUnit ?? `-`}  ${transformDate}`;
      }
    });
  }
  /**
   * Get filter options by column name.
   * @param itemName column name
   * @returns
   *
   *  Promise<ClaimStatusResponse>
   */
  private async getFilterOptionsByColumn(
    columnName: string
  ): Promise<DropDownOption[]> {
    let dropdownOptions = [] as DropDownOption[];
    if (columnName == POD_COUNTRY) {
      dropdownOptions = await this.mapTypeToDropDownOptions(
        this.globalService.countries$,
        'name',
        'name'
      );
    } else if (columnName == OPERATOR_CODE) {
      dropdownOptions = await this.mapTypeToDropDownOptions(
        this.globalService.carrierCodes$,
        'operatorName',
        'operatorCode'
      );
    } else if (columnName == POD_CLUSTER) {
      dropdownOptions = await this.mapTypeToDropDownOptions(
        this.globalService.countryClusters$,
        'clusterName',
        'clusterName'
      );
    } else if (columnName == POD_LOCAL_CURRENCY) {
      const date =
        !!this.caseDetail?.workOrderDate &&
        Date.parse(new Date(this.caseDetail?.workOrderDate).toISOString()) > 0
          ? new Date(this.caseDetail?.workOrderDate).toISOString()
          : !!this.caseDetail?.dateOfIncident &&
              Date.parse(
                new Date(this.caseDetail?.dateOfIncident).toISOString()
              ) > 0
            ? new Date(this.caseDetail?.dateOfIncident).toISOString()
            : new Date().toUTCString();
      const currencyAndExchangeRate$ = this._commonService
        .commonCurrencyAndExchangeRatesForQuotationDateGet(
          date,
          this.apiVersion
        )
        .pipe(
          map(
            (response) =>
              (response?.data
                ? response.data
                : null) as CurrencyAndExchangeRateDto[]
          ),
          tap((data) => (this.currencyAndExchangeRate = data))
        );
      dropdownOptions = await this.mapTypeToDropDownOptions(
        currencyAndExchangeRate$,
        'quotationCurrency',
        'quotationCurrency'
      );
    }
    return dropdownOptions;
  }

  async mapTypeToDropDownOptions<T>(
    list$: Observable<T[]>,
    labelProperty: string,
    valueProperty: string
  ) {
    return (await lastValueFrom(
      list$.pipe(
        map((list) =>
          list.map((entry: T) => ({
            label: (entry as { [key: string]: string })[labelProperty],
            value: (entry as { [key: string]: string })[valueProperty],
          }))
        )
      )
    )) as DropDownOption[];
  }

  formValidityChanged(isValid: boolean) {
    this.globalService.updateNextButtonStateOnFormValidation(isValid);
  }
}
