import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { WorkflowSteps } from '../../../common/models/workflowSteps';
import { TemplateModel } from '@maersk-global/angular-shared-library/lib/models/template-model';
import { stageLoad } from '../../../common/models/stageLoad';
import { combineLatest, map, Observable, tap } from 'rxjs';
import { GlobalService } from '../../../global-service';

@Component({
  selector: 'app-workflow-stepper',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './app-workflow-stepper.component.html',
  styleUrl: './app-workflow-stepper.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppWorkflowStepperComponent {
  @Input({ required: true }) stages!: TemplateModel[] | null;
  @Output() loadSelectedStage = new EventEmitter<stageLoad>();
  nextStageId: number = 0;
  workflowSteps?: WorkflowSteps[] = [];
  currentStageSequence: number = 0;
  anchorStageId: number = 1;
  currentStageId: number = 1;

  constructor(private globalService: GlobalService) {}

  currentStageId$: Observable<number> = combineLatest([
    this.globalService.currentStageId$,
    this.globalService.anchorStageId$,
  ]).pipe(
    tap(([currentStageId, anchorId]) => {
      this.currentStageId = currentStageId;
      this.currentStageSequence =
        this.stages?.filter((o) => o.id == currentStageId)[0]?.sequence ?? 0;
      this.anchorStageId = anchorId;
      this.loadStepper();
    }),
    map(([currentStageId]) => currentStageId)
  );

  loadStepper() {
    if (!this.stages && this.stages!.length == 0) return;
    const filteredStages = this.stages
      ?.filter((o) => !o.hidden)
      .sort((a, b) => ((a.sequence ?? 0) > (b.sequence ?? 0) ? 1 : -1));
    if (this.currentStageId <= this.anchorStageId) {
      this.workflowSteps = filteredStages?.map((o) => {
        if (o.sequence == undefined) return;
        return this.getWorkFlowStepsProperties(o);
      }) as WorkflowSteps[];
    } else {
      this.workflowSteps = filteredStages?.map((stage) => {
        if (stage.sequence == undefined) return;
        return {
          name: stage.label,
          state: 'pending',
          icon: '',
          className: 'pending',
        } as WorkflowSteps;
      }) as WorkflowSteps[];
    }
  }

  getWorkFlowStepsProperties(stage: TemplateModel) {
    let icon = '';
    let clsName = '';
    let state = 'pending';
    const stageSequence = stage.sequence! + 1;
    const name = stage.label;

    // check state of the stage
    if (stageSequence == this.currentStageId) {
      if (stageSequence < this.anchorStageId) {
        state = 'completed';
      } else if (stageSequence == this.anchorStageId) {
        state = 'current';
      }
    } else {
      if (stageSequence > this.anchorStageId) {
        state = 'pending';
      } else if (stageSequence < this.anchorStageId) {
        state = 'completed';
      }
    }

    if (stageSequence == this.anchorStageId) {
      icon = '';
      clsName = 'stage-current';
      state = 'current';
    }

    if (stageSequence == this.currentStageId) {
      icon = 'check';
      clsName = 'current';
    } else if (
      stageSequence < this.currentStageId &&
      stageSequence < this.anchorStageId
    ) {
      icon = 'check';
      clsName = 'completed';
    } else if (
      stageSequence > this.currentStageId &&
      stageSequence < this.anchorStageId
    ) {
      icon = 'check';
      clsName = 'completed';
    }

    return {
      name: name,
      state: state,
      icon: icon,
      className: clsName,
    } as WorkflowSteps;
  }

  showStage(sequence: number) {
    if (this.currentStageSequence == sequence) return;
    const anchorSequenceID =
      this.stages?.filter((o) => o.id == this.anchorStageId)[0]?.sequence ?? 0;
    if (sequence > anchorSequenceID) return;
    this.currentStageSequence = sequence;
    const stageID =
      this.stages?.filter((o) => o.sequence == sequence)[0]?.id ?? 0;
    const newStage = this.stages?.filter((i: any) => i.id == stageID)[0];
    if (newStage) {
      this.loadSelectedStage.emit({
        template: newStage,
        refreshAnchorNeeded: false,
      } as stageLoad);
      this.globalService.updateCurrentStageId(stageID);
    }
  }
}
