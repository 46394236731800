import { ApplicationConfig } from '@angular/core';
import { provideRouter, withHashLocation } from '@angular/router';
import { routes } from './app.routes';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ForgeRockInterceptor } from './helper/forge-rock.interceptor';
import {
  EmailModalService,
  HEADER_MENU_PROVIDER,
  ModalNotificationService,
  SIDE_NAV_PROVIDER,
  ToasterService,
} from '@maersk-global/angular-shared-library';
import { SideNavMenuService } from './common/services/side-nav-menu/side-nav-menu.service';
import { HeaderMenuService } from './common/services/header-menu/header-menu.service';
import { GlobalService } from './global-service';
import { CommonService } from './common/services/customer-recovery/common.service';
import { ApplicationConfigService } from './common/services/common/applicationConfig.service';
import { CustomerRecoveryClaimService } from './common/services/customer-recovery/customer-recovery-claim.service';
import {CaseService} from './common/services/case/case.service';
import { WorkflowService } from './common/services/customer-recovery/workflow.service';
import { MaterialCodeService } from './common/services/common/materialCode.service';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withHashLocation()),
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ForgeRockInterceptor,
      multi: true,
    },
    { provide: SIDE_NAV_PROVIDER, useClass: SideNavMenuService },
    { provide: HEADER_MENU_PROVIDER, useClass: HeaderMenuService },
    CookieService,
    ModalNotificationService,
    ToasterService,
    EmailModalService,
    ApplicationConfigService,
    CommonService,
    CustomerRecoveryClaimService,
    CaseService,
    MaterialCodeService,
    GlobalService,
    WorkflowService,
  ],
};
