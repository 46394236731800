import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { TemplateModel } from '@maersk-global/angular-shared-library/lib/models/template-model';
import { CommonModule } from '@angular/common';
import { AppWorkflowAttachmentComponent } from '../app-workflow-attachment/app-workflow-attachment.component';
import { AppWorkflowOverviewComponent } from '../app-workflow-overview/app-workflow-overview.component';
import { AppWorkflowBookingInfoComponent } from '../app-workflow-booking-info/app-workflow-booking-info.component';
import { AppWorkflowContainerInfoComponent } from '../app-workflow-container-info/app-workflow-container-info.component';
import { AppWorkflowCargoDetailsComponent } from '../app-workflow-cargo-details/app-workflow-cargo-details.component';
import { CustomerRecoveryClaimService } from '../../../common/services/customer-recovery/customer-recovery-claim.service';
import {
  Observable,
  catchError,
  combineLatest,
  firstValueFrom,
  from,
  lastValueFrom,
  map,
  of,
  tap,
} from 'rxjs';
import { gcssBookingInfo } from '../../../common/models/gcssBookingInfo';
import { AppWorkflowLiabilityDetailsComponent } from '../app-workflow-liability-details/app-workflow-liability-details.component';
import { AppWorkflowDamageDetailsComponent } from '../app-workflow-damage-details/app-workflow-damage-details.component';
import { GlobalService } from '../../../global-service';
import { CaseDetailsDto } from '../../../common/models/caseDetailsDto';
import { CustomerRecoveryCaseDto } from '../../../common/models/customerRecoveryCaseDto';
import { WorkflowService } from '../../../common/services/customer-recovery/workflow.service';
import { CompleteTaskRequestDTO } from '../../../common/models/completeTaskRequestDTO';
import { Router } from '@angular/router';
import { AppWorkflowActivityLogComponent } from '../app-workflow-activity-log/app-workflow-activity-log.component';
import { AppWorkflowWorkOrdersComponent } from '../app-workflow-work-orders/app-workflow-work-orders.component';
import {
  Loader,
  LoaderService,
  Toast,
  ToasterService,
} from '@maersk-global/angular-shared-library';
import { AppWorkflowLiablePartyDetailsComponent } from '../app-workflow-liable-party-details/app-workflow-liable-party-details.component';
import { WorkOrderAndLineItemsDto } from '../../../common/models/workOrderAndLineItemsDto';
import { ClaimStatusRequest } from '../../../common/models/claim-status-request';
import { LiabilityLetterComponent } from '../liability-letter/liability-letter.component';
import { LiabilityLettersLogComponent } from '../liability-letters-log/liability-letters-log.component';
import { SendMailRequest } from '../../../common/models/sendMailRequest';
import { InvoiceLettersLogComponent } from '../invoice-letters-log/invoice-letters-log.component';
import { WorkflowCompletedComponent } from '../workflow-completed/workflow-completed.component';
import { CaseAssignmentStatusResponse } from '../../../common/models/caseAssignmentStatusResponse';
import { stageLoad } from '../../../common/models/stageLoad';
import { InvoiceSummaryComponent } from '../invoice-summary/invoice-summary.component';
import { InvoiceLetterComponent } from '../invoice-letter/invoice-letter.component';
import {
  CancelReasonsList,
  CaseTypeEnum,
  workflowStages,
} from '../../customer-recovery/temporary-constant';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ClaimStatusResponse } from '../../../common/models/claim-status-response';
import { WorkFlowStage } from '../../../common/models/workflowStage';
import { CaseInvoiceDetailDto } from '../../../common/models/caseInvoiceDetailDto';
import { InvoiceStatusEnum } from '../../../common/models/invoiceStatusEnum';
import { AppWorkflowManualEstimatesComponent } from '../app-workflow-manual-estimates/app-workflow-manual-estimates.component';
import { AppWorkflowLiabilityDetailsManualEstimatesComponent } from '../app-workflow-liability-details-manual-estimates/app-workflow-liability-details-manual-estimates.component';
import { LiabilityLetterStatus } from '../../../common/models/liabilityLetterStatus';
import { AppWorkflowCpInfoComponent } from '../app-workflow-cp-info/app-workflow-cp-info.component';
import { RepairLineItem } from '../../../common/models/repairLineItem';
@Component({
  selector: 'app-app-workflow-tab',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AppWorkflowAttachmentComponent,
    AppWorkflowOverviewComponent,
    AppWorkflowBookingInfoComponent,
    AppWorkflowCpInfoComponent,
    AppWorkflowContainerInfoComponent,
    AppWorkflowCargoDetailsComponent,
    AppWorkflowLiabilityDetailsComponent,
    AppWorkflowDamageDetailsComponent,
    AppWorkflowManualEstimatesComponent,
    AppWorkflowActivityLogComponent,
    AppWorkflowWorkOrdersComponent,
    AppWorkflowLiablePartyDetailsComponent,
    LiabilityLetterComponent,
    LiabilityLettersLogComponent,
    InvoiceLettersLogComponent,
    WorkflowCompletedComponent,
    InvoiceLetterComponent,
    InvoiceSummaryComponent,
    AppWorkflowLiabilityDetailsManualEstimatesComponent,
  ],
  templateUrl: './app-workflow-tab.component.html',
  styleUrl: './app-workflow-tab.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [CustomerRecoveryClaimService],
})
export class AppWorkflowTabComponent {
  @Input({ required: true }) stages!: TemplateModel[] | null;

  anchorTaskId?: string;
  anchorStageId: number = 1;
  @ViewChild('letterComponent', { static: false })
  letterComponent!: LiabilityLetterComponent;

  @ViewChild('invoice', { static: false })
  invoiceLetterComponent!: InvoiceLetterComponent;

  @ViewChild('party', { static: false })
  partyComponent!: AppWorkflowLiablePartyDetailsComponent;

  @ViewChild('damageDetails', { static: false })
  damageDetailsComponent!: AppWorkflowDamageDetailsComponent;

  @Output() stageMovedToNextOrBack = new EventEmitter<stageLoad>();

  currentStageId: number = 1;
  bookingCargoDetail!: gcssBookingInfo;
  caseDetails!: CustomerRecoveryCaseDto;
  apiVersion: string = '1.0';
  caseNumber: string = '';
  currentTabIndex: number = 0;
  tabItems?: TemplateModel[];
  workOrders!: WorkOrderAndLineItemsDto[];
  nextStageId: number = 0;
  userId: string = sessionStorage.getItem('userId') || '';
  liabilityEmailContent?: SendMailRequest;
  LIABILITY_STAGE_ID: number = 3;
  DAMAGE_ESTIMATION_STAGE_ID: number = 2;
  NEW_STAGE_ID: number = 1;
  triggerMail: boolean = false;
  containerNumber: string = '';
  loadTab: boolean = true;
  closeCase: boolean = false;
  cancelReasons: string[] = CancelReasonsList;
  currentStageItem: TemplateModel[] = [];
  closeCaseForm: FormGroup = new FormGroup({
    closeReason: new FormControl('', { updateOn: 'change' }),
    comments: new FormControl('', { updateOn: 'change' }),
  });
  closedStatusId: number = 6;
  isSaveOperationInProgress: boolean = false;
  isNextButtonEnabled: boolean = true;
  isNextButtonHidden: boolean = true;
  OTHERS: string = 'Others';
  closeReason: string = '';

  constructor(
    private _customerRecoveryService: CustomerRecoveryClaimService,
    private globalService: GlobalService,
    private _workflowService: WorkflowService,
    private toaster: ToasterService,
    private loader: LoaderService,
    private router: Router
  ) {
    this.globalService.hideNextBtn$.subscribe((value) => {
      this.isNextButtonHidden = value;
    });

    this.globalService.enableNextBtn$.subscribe((value) => {
      this.isNextButtonEnabled = value;
    });
  }

  hasIssuedLiabilityLetters$: Observable<boolean> =
    this.globalService.liabilityLetters$.pipe(
      map((letters) => !!letters && letters.length > 0)
    );

  disableForm$: Observable<boolean> = this.globalService.disableForm$;
  enableCloseBtn$: Observable<boolean> = this.globalService.enableCloseBtn$;
  reopenCase$: Observable<boolean> = this.globalService.reopenCase$;

  shouldShowLiabilityDetails$: Observable<boolean> =
    this.globalService.shouldShowLiabilityDetails$;

  shouldShowIssueInvoiceScreen$: Observable<boolean> =
    this.globalService.shouldShowIssueInvoiceScreen$;

  currentStageId$: Observable<number> = this.globalService.currentStageId$.pipe(
    tap((id) => {
      this.currentStageId = id;
      this.loadTabInitialData(id);
    })
  );

  anchorDetails$: Observable<boolean> = combineLatest([
    this.globalService.anchorFlowTaskId$,
    this.globalService.anchorStageId$,
    this.globalService.anchorTaskIdLoaded$,
  ]).pipe(
    tap(([taskId, anchorStageId]) => {
      this.anchorTaskId = taskId;
      this.anchorStageId = anchorStageId;
    }),
    map(([taskId, anchorStageId, loaded]) => (taskId && loaded ? true : false))
  );

  customerRecoveryData$ = this.globalService.customerRecoveryData$.pipe(
    tap((recoveryData) => {
      this.caseDetails = recoveryData;
      this.caseNumber = recoveryData?.recoveryCaseNumber ?? '';
      this.containerNumber = recoveryData?.containerNumber ?? '';
      this.closeReason =
        recoveryData.cancellationReason === this.OTHERS
          ? `Reason: ${recoveryData.cancellationReason} - ${recoveryData.comments}`
          : `Reason: ${recoveryData.cancellationReason}`;
    })
  );

  isCountryS4HanaMigrated$: Observable<boolean> = combineLatest([
    this.globalService.customerRecoveryData$,
    this.globalService.countries$,
  ]).pipe(
    map(
      ([customerRecoveryData, countries]) =>
        !!countries.find(
          (country) =>
            country.code === customerRecoveryData.podCountryCode &&
            country.isS4HanaMigrated
        )
    )
  );

  loadTabInitialData(stageId: number): void {
    this.currentTabIndex = 0;
    const caseStageTemplate = this.stages?.filter(
      (i) => i.id === stageId
    )[0] as TemplateModel;
    this.currentStageItem =
      caseStageTemplate.items?.filter((i) => i.name === 'tab')[0].items ?? [];
    if (this.currentStageItem)
      this.tabItems = this.currentStageItem[this.currentTabIndex].items;
    const currentStageSequence =
      this.stages?.filter((i) => i.id == stageId)[0].sequence ?? 0;
    if (
      this.stages &&
      (this.stages[this.stages.length - 1].sequence ?? 0) > currentStageSequence
    )
      this.nextStageId =
        this.stages?.filter((i) => i.sequence == currentStageSequence + 1)[0]
          .id ?? 1;
  }

  rowSelectionChanged(event: any) {}

  async onNextClick() {
    const shouldShowLiabilityDetails =
      (await firstValueFrom(this.shouldShowLiabilityDetails$)) ?? false;
    const shouldShowIssueInvoiceScreen =
      (await firstValueFrom(this.shouldShowIssueInvoiceScreen$)) ?? false;

    const isCountryS4HanaMigrated =
      (await firstValueFrom(this.isCountryS4HanaMigrated$)) ?? false;

    const disableForm = await firstValueFrom(this.globalService.disableForm$);
    if (disableForm) {
      this.loadNextStage(false);
      return;
    }
    this.isSaveOperationInProgress = true;
    // Adding this try catch to make sure we will always have the next button enabled if save operation fails.
    // TODO: The error handling needs to be re-looked for all the API calls.
    try {
      if (this.currentStageId === 1) {
        await this.stageOverviewSaveAndSubmit();
      } else if (this.currentStageId === 2) {
        await this.stageDamageEstimationSaveAndSubmit();
      } else if (this.currentStageId === 3 && shouldShowLiabilityDetails) {
        await this.stageLiabilitySave();
      } else if (this.currentStageId === 3 && !shouldShowLiabilityDetails) {
        await this.stageSubmit();
      } else if (this.currentStageId === 4 && !isCountryS4HanaMigrated) {
        await this.stageSubmit();
      } else if (
        this.currentStageId === 4 &&
        shouldShowIssueInvoiceScreen &&
        isCountryS4HanaMigrated
      ) {
        await this.createInvoice();
      } else if (
        this.currentStageId === 4 &&
        !shouldShowIssueInvoiceScreen &&
        isCountryS4HanaMigrated
      ) {
        let latestInvoices = await firstValueFrom(this.globalService.invoices$);
        latestInvoices = this.sortInvoicesByCreatedDateDesc(latestInvoices);

        if (
          latestInvoices[0].invoiceStatusCode &&
          [
            InvoiceStatusEnum.InvoiceIssued,
            InvoiceStatusEnum.PaymentReceived,
          ].includes(latestInvoices[0].invoiceStatusCode)
        ) {
          await this.stageSubmit();
        } else {
          this.toaster.showToast({
            message:
              'You can only complete the stage if the invoice has been issued or the payment has been received.',
            type: 'warning',
          });
        }
      }
    } catch (error) {
      console.error('Error occurred while saving data');
    } finally {
      this.isSaveOperationInProgress = false;
    }
  }

  async createInvoice() {
    await this.invoiceLetterComponent.createInvoice();
  }

  async stageOverviewSaveAndSubmit() {
    //overview details update
    //need to use customer recoverycaseDto obj from global service - TODO
    const customerRecoveryCaseDto = this.caseDetails;

    await this.assignCurrentCaseToTheCurrentUser(customerRecoveryCaseDto);

    const currentItems = this.currentStageItem[this.currentTabIndex];
    const overViewDetails = currentItems.items?.filter(
      (item) => item.name == 'overview'
    )[0];
    overViewDetails?.items?.forEach((i: TemplateModel) => {
      if (i.name == 'dateOfIncident' && i.value) {
        customerRecoveryCaseDto.dateOfIncident =
          this.convert_dd_mm_yyyy_into_dateFormat(i.value);
      }
      if (i.name == 'placeOfIncident') {
        customerRecoveryCaseDto.placeOfIncident = i.value;
      }
      if (i.name == 'exchangerate' && i.value) {
        customerRecoveryCaseDto.exchangeRateUSDCaseCurrency = (
          i.value as string
        ).split(' ')[0] as unknown as number;
      }
      if (i.name == 'podLocalCurrency' && i.value) {
        customerRecoveryCaseDto.caseCurrency = i.value;
      }
    });
    if (this.caseDetails?.recoveryCaseNumber)
      customerRecoveryCaseDto.recoveryCaseNumber =
        this.caseDetails?.recoveryCaseNumber;
    customerRecoveryCaseDto.workFlowVersion = 1; //temp version hard coded. When we get template this should be removed.
    const caseRequest = {
      customerRecoveryCaseDto: customerRecoveryCaseDto,
    } as CaseDetailsDto;

    await firstValueFrom(
      this._customerRecoveryService.customerRecoveryClaimsUpdatePost(
        caseRequest,
        this.apiVersion
      )
    );
    if (this.currentStageId == this.anchorStageId) {
      await this.movingStageNextOrBackWithAnchorFlow(
        {
          IsSubmit: true,
          TaskId: this.anchorTaskId,
        } as CompleteTaskRequestDTO,
        this.nextStageId,
        {
          claimStatusId: this.nextStageId,
          userName: this.userId,
          updatedDttm: new Date(),
          workFlowStage: workflowStages[this.nextStageId],
        } as ClaimStatusRequest
      );
    } else {
      this.globalService.reloadDamageDetails();
      this.loadNextStage(false);
    }
  }

  /**
   * Assign this case to the current user if it is not already assigned.
   * @param customerRecoveryCaseDto CustomerRecoveryCaseDto
   * @returns Promise
   */
  async assignCurrentCaseToTheCurrentUser(
    customerRecoveryCaseDto: CustomerRecoveryCaseDto
  ) {
    if (
      customerRecoveryCaseDto.assignedToUID === this.userId ||
      !customerRecoveryCaseDto.recoveryCaseNumber
    )
      return;

    const cases = [];
    const groupedCases = await lastValueFrom(
      this._customerRecoveryService.customerRecoveryClaimsListByGroupIdPost(
        [customerRecoveryCaseDto.groupId ?? ''],
        this.apiVersion
      )
    );
    if (groupedCases && !!groupedCases.customerRecoveryDetails) {
      cases.push(
        ...groupedCases.customerRecoveryDetails.map(
          (recoveryCase: CustomerRecoveryCaseDto) =>
            recoveryCase.recoveryCaseNumber
        )
      );
    }
    await lastValueFrom(
      this._customerRecoveryService
        .customerRecoveryClaimsCaseAssignmentPost(
          {
            assignedToUid: this.userId,
            recoveryCaseNumbers: cases,
            updatedBy: this.userId,
            updatedDttm: new Date(),
          },
          this.apiVersion
        )
        .pipe(
          tap((response: CaseAssignmentStatusResponse) => {
            if (
              response &&
              response.status?.statusCode == 200 &&
              response.caseAssignmentStatus?.isUpdateSuccessful
            ) {
              this.caseDetails.assignedToUID = this.userId;
            }
          }),
          catchError((error: any) => {
            //returning true as we need to proceed other execution flow.
            return of(true);
          })
        )
    );
  }

  async stageDamageEstimationSaveAndSubmit() {
    const cpDetails = await firstValueFrom(this.globalService.cpInformation$);
    const recoveryDetails = this.caseDetails;
    if (
      cpDetails &&
      recoveryDetails &&
      cpDetails.cpCoverageCurrency == 'USD' &&
      this.globalService.caseType == CaseTypeEnum.CaseWithOutWorkOrder
    ) {
      recoveryDetails.aboveCoverageCostUSD = cpDetails.aboveCoverageCostUSD;
      recoveryDetails.recoverableCostUSD = cpDetails.recoverableCostUSD;
      recoveryDetails.withinCoverageCostUSD = cpDetails.withinCoverageCostUSD;
    }
    const caseRequest = {
      customerRecoveryCaseDto: recoveryDetails,
    } as CaseDetailsDto;

    // Convert the service call to an observable
    const customerRecoveryUpdate$ = from(
      this._customerRecoveryService.customerRecoveryClaimsUpdatePost(
        caseRequest,
        this.apiVersion
      )
    );

    // Convert the saveDamageDetailsToServer call to an observable
    const saveDamageDetails$ = from(
      this.damageDetailsComponent.saveDamageDetailsToServer()
    );

    if (this.globalService.caseType == CaseTypeEnum.CaseWithWorkOrder) {
      // Subscribe or use await to get the results
      await firstValueFrom(saveDamageDetails$).then((o) => {
        this.stageSubmit();
      });
    } else {
      // Combine the latest values from both observables
      const combined$ = combineLatest([
        customerRecoveryUpdate$,
        saveDamageDetails$,
      ]);

      // Subscribe or use await to get the results
      await firstValueFrom(combined$).then((o) => {
        //this.globalService.updateCustomerRecoveryData(recoveryDetails);
        this.stageSubmit();
      });
    }
  }

  async stageLiabilitySave() {
    //save liabilityParty after success send mail
    const response = await this.partyComponent.saveLiabilityParties();
    if (response.isSuccess) {
      await this.globalService.reloadLiabilityPartyDetails();
      this.currentStageItem[this.currentTabIndex].items?.forEach(
        (i) => (i.loaded = false)
      );
      await this.letterComponent.sendMail();
    }
  }

  async stageSubmit() {
    const statusReq = {
      claimStatusId: this.nextStageId,
      userName: this.userId,
      updatedDttm: new Date(),
      workFlowStage: workflowStages[this.nextStageId],
    } as ClaimStatusRequest;
    const nextData = {
      IsSubmit: true,
      TaskId: this.anchorTaskId,
    } as CompleteTaskRequestDTO;
    if (this.currentStageId == this.anchorStageId) {
      await this.movingStageNextOrBackWithAnchorFlow(
        nextData,
        this.nextStageId,
        statusReq
      );
    } else {
      this.loadNextStage(false);
    }
  }

  async closingCase() {
    const { closeReason, comments } = this.closeCaseForm.value;
    const closeRequest = {
      userName: this.userId,
      cancelationReason: closeReason,
      comment: closeReason != this.OTHERS ? '' : comments,
      claimStatusId: this.closedStatusId,
      updatedDttm: new Date(),
      workFlowStage: this.getWorkFlowStageById(
        this.caseDetails.recoveryStatusId ?? 1
      ),
    };
    this.claimStatusUpdate(
      closeRequest,
      true,
      this.caseDetails.recoveryStatusId === 1 || false
    );
  }

  reOpeningCase() {
    const closeRequest = {
      userName: this.userId,
      cancelationReason: '',
      comment: '',
      claimStatusId: this.caseDetails.workFlowStage
        ? workflowStages[this.caseDetails.workFlowStage]
        : 1,
      updatedDttm: new Date(),
      workFlowStage: this.getWorkFlowStageById(
        this.caseDetails.recoveryStatusId ?? 1
      ),
    };
    this.claimStatusUpdate(closeRequest, false, false);
  }

  async claimStatusUpdate(
    request: ClaimStatusRequest,
    close: boolean,
    newCase: boolean
  ) {
    const closedResponse: ClaimStatusResponse = await lastValueFrom(
      this._customerRecoveryService.customerRecoveryClaimsClaimStatusCaseNumberPut(
        request,
        this.caseNumber,
        this.apiVersion
      )
    );
    if (!closedResponse.claimStatusChange.isClaimStatusChangeSuccessful) return;
    this.caseDetails.recoveryStatusId = request.claimStatusId;
    this.globalService.updateCustomerRecoveryData(this.caseDetails);
    if (close) {
      this.globalService.updateTabSelected(1);
      if (newCase) {
        //update work_flow_version for new case --
        this.updateWorkFlowVersionForNewCase();
      }
      this.router.navigate(['customer-recovery']);
    }
  }
  updateWorkFlowVersionForNewCase() {
    this.caseDetails.workFlowVersion = 1;
    const caseRequest = {
      customerRecoveryCaseDto: this.caseDetails,
    } as CaseDetailsDto;
    firstValueFrom(
      this._customerRecoveryService.customerRecoveryClaimsUpdatePost(
        caseRequest,
        this.apiVersion
      )
    );
  }

  onWorkOrdersLoaded(workOrders: WorkOrderAndLineItemsDto[]) {
    this.workOrders = workOrders;
  }

  async movingStageNextOrBackWithAnchorFlow(
    request: CompleteTaskRequestDTO,
    stageId: number,
    dbRequest: ClaimStatusRequest
  ) {
    this.loader.showLoader({
      label: 'Loading',
      size: 'medium',
    } as Loader);
    this.loadTab = false;
    //After Save success submit stage in anchor
    const anchorResponse = await lastValueFrom(
      this._workflowService.workflowCompleteTask(request).pipe(
        map((res) => res),
        catchError((error) => {
          this.loader.hideLoader();
          this.toaster.showToast({
            message: 'Failed moving to stage.',
            type: 'error',
          } as Toast);
          return of();
        })
      )
    );
    if (anchorResponse.statusCode == 200 || anchorResponse.statusCode == 204) {
      //After anchor stage is update then update claim status id in db
      await this.updateRecoveryClaimId(dbRequest, stageId);
    }
    this.loadTab = true;
    this.loader.hideLoader();
  }

  onTabChange(event: any) {
    this.currentTabIndex = event.detail;
    this.tabItems = this.currentStageItem[event.detail].items;
  }

  onLiabilityPartySelected(event: SendMailRequest) {
    if (!event) {
      this.liabilityEmailContent = event;
      this.globalService.updateNextButtonStateOnFormValidation(false);
      return;
    }
    this.liabilityEmailContent = { ...event };
  }

  onBackClick() {
    this.loader.showLoader({
      label: 'Loading',
      size: 'medium',
    } as Loader);
    this.loadTab = false;
    const currentStageSequence =
      this.stages?.filter((i) => i.id == this.currentStageId)[0].sequence ?? 0;
    const prevStage = this.stages?.filter(
      (i) => i.sequence == currentStageSequence - 1
    )[0];

    if (prevStage) {
      this.currentStageItem[this.currentTabIndex].items?.forEach(
        (i) => (i.loaded = false)
      );
      this.stageMovedToNextOrBack.emit({
        template: prevStage,
        refreshAnchorNeeded: false,
      } as stageLoad);
      if (prevStage.id) this.globalService.updateCurrentStageId(prevStage.id);
    }
    this.loadTab = true;
    this.loader.hideLoader();
  }

  async updateRecoveryClaimId(request: ClaimStatusRequest, newStageId: number) {
    const statusResponse = await lastValueFrom(
      this._customerRecoveryService
        .customerRecoveryClaimsClaimStatusCaseNumberPut(
          request,
          this.caseNumber,
          this.apiVersion
        )
        .pipe(map((response) => response))
    );
    if (
      statusResponse.claimStatusChange &&
      statusResponse.claimStatusChange.isClaimStatusChangeSuccessful
    ) {
      this.caseDetails.workFlowStage = this.getWorkFlowStageById(
        this.nextStageId
      );
      this.caseDetails.recoveryStatusId = request.claimStatusId;
      this.globalService.updateCustomerRecoveryData(this.caseDetails);
      this.loadNextStage(true);
    }
  }

  getWorkFlowStageById(claimId: number): WorkFlowStage {
    let stageName = WorkFlowStage.New;
    switch (claimId) {
      case 1:
        stageName = WorkFlowStage.New;
        break;
      case 2:
        stageName = WorkFlowStage.DamageEstimation;
        break;
      case 3:
        stageName = WorkFlowStage.Liability;
        break;
      case 4:
        stageName = WorkFlowStage.Invoice;
        break;
      case 5:
        stageName = WorkFlowStage.Complete;
    }
    return stageName as WorkFlowStage;
  }

  loadNextStage(refreshNeed: boolean) {
    const newStage = this.stages?.filter(
      (i: any) => i.id == this.nextStageId
    )[0];
    if (newStage) {
      this.currentStageItem[this.currentTabIndex].items?.forEach(
        (i) => (i.loaded = false)
      );
      this.stageMovedToNextOrBack.emit({
        template: newStage,
        refreshAnchorNeeded: refreshNeed,
      } as stageLoad);
      this.globalService.updateCurrentStageId(this.nextStageId);
    }
  }

  /**
   * Converting date to a specified format.
   * @param date date
   * @returns
   */
  private convert_dd_mm_yyyy_into_dateFormat(date: string) {
    const arrDate = date?.split('-');
    if (!date) return undefined;
    else
      return new Date(
        `${arrDate[2]}-${arrDate[1]}-${arrDate[0]}T${'00:00:00.000'}Z`
      );
  }

  private sortInvoicesByCreatedDateDesc(
    caseInvoiceDetails: CaseInvoiceDetailDto[] | undefined
  ) {
    return caseInvoiceDetails
      ? caseInvoiceDetails.sort((a, b) => {
          const dateA = a.createdDate ? new Date(a.createdDate).getTime() : 0;
          const dateB = b.createdDate ? new Date(b.createdDate).getTime() : 0;
          return dateB - dateA;
        })
      : [];
  }
}
