import { CommonModule, DatePipe } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  CellClickEvent,
  DropDownOption,
  GridCellData,
  GridColumnSchema,
  GridComponent,
  GridRowData,
  PanelComponent,
  TemplateModel,
  NoDataComponent,
  CustomFormValidators,
  TableSkeletonLoaderComponent,
} from '@maersk-global/angular-shared-library';
import { Router } from '@angular/router';
import {
  catchError,
  firstValueFrom,
  lastValueFrom,
  map,
  Observable,
  of,
  shareReplay,
  Subject,
  switchMap,
  tap,
} from 'rxjs';
import { CaseService } from '../../common/services/case/case.service';
import * as gridSchema from './grid-schema';
import { WorkOrder } from '../../common/models/workOrder';
import { AppWorkflowContainerMovesComponent } from '../custom-workflow/app-workflow-container-moves/app-workflow-container-moves.component';
import * as template from '../../../assets/json/dcrp-workflow.json';
import { GlobalService } from '../../global-service';
import { CountryDto } from '../../common/models/countryDto';
import { environment } from '../../../environments/environment';
import { ContainerMoveDto } from '../../common/models/containerMoveDto';
import { ContainerProtectService } from '../../common/services/container-protect/container-protect.service';
import { ContainerProtectDto } from '../../common/models/containerProtectDto';
import { CommonService } from '../../common/services/customer-recovery/common.service';

@Component({
  selector: 'app-create-manual-case',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormsModule,
    GridComponent,
    CommonModule,
    NoDataComponent,
    PanelComponent,
    AppWorkflowContainerMovesComponent,
    TableSkeletonLoaderComponent,
  ],
  templateUrl: './create-manual-case.component.html',
  styleUrl: './create-manual-case.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [ContainerProtectService, DatePipe, CommonService],
})
export class CreateManualCaseComponent {
  @Output() onModalCancel = new EventEmitter<boolean>();
  @ViewChild('containerMoveSection')
  containerMoveSection?: ElementRef;
  userId: string = sessionStorage.getItem('userId') || '';
  searchToDateUTC = new Date();
  searchFromDateUTC = new Date();
  selectedWorkOrder: number | undefined;
  selectedMove?: [{ [key: string]: GridCellData }];
  containerNumberSubject$$: Subject<string> = new Subject<string>();
  searchedContainerNumber?: string;
  caseCreationWithoutWorkOrder = false;
  cpDetailsLoading = false;
  showContainerMoves = false;
  areWorkOrdersLoading = false;
  workOrderSearchInterval = environment.searchIntervalForManualCase;
  containerMovesSearchInterval = environment.searchIntervalForContainerMoves;
  currentDate = new Date().toISOString().substring(0, 10);
  movesFromDate = '';
  movesToDate = new Date().toISOString().substring(0, 10);
  containerProperties: ContainerMoveDto | undefined;
  dateFormat = 'dd-MM-yyyy';
  containerProtectionList: Array<ContainerProtectDto> = [];
  private apiVersion: string = '1.0';

  get containerNumberInput() {
    return this.newCaseForm.controls['containerNumberInput'];
  }

  get repairCountry() {
    return this.newCaseForm.controls['repairCountry'];
  }

  get incidentDate() {
    return this.newCaseForm.controls['incidentDate'];
  }

  get containerMovesFromDate() {
    return this.newCaseForm.controls['containerMovesFromDate'];
  }

  get containerMovesToDate() {
    return this.newCaseForm.controls['containerMovesToDate'];
  }

  get disableCaseCreation() {
    return (
      (!this.caseCreationWithoutWorkOrder && !this.selectedWorkOrder) ||
      this.cpDetailsLoading ||
      (this.caseCreationWithoutWorkOrder &&
        (!this.selectedMove || this.newCaseForm.invalid))
    );
  }

  containerMovesItem: TemplateModel = template.stages[0].items[0].items[0].items
    .find((item) => item.name === 'bookingInformation')
    ?.items?.find((item) => item.name === 'containerMoves') as TemplateModel;

  newCaseForm: FormGroup = new FormGroup({
    containerNumberInput: new FormControl('', {
      validators: [
        CustomFormValidators.ContainerNumberValidator,
        CustomFormValidators.AlphanumericValidator,
      ],
      updateOn: 'change',
    }),
    incidentDate: new FormControl('', {
      validators: [Validators.required],
      updateOn: 'change',
    }),
    repairCountry: new FormControl('', {
      validators: [Validators.required],
      updateOn: 'change',
    }),
    containerMovesFromDate: new FormControl('', {
      validators: [Validators.required],
      updateOn: 'change',
    }),
    containerMovesToDate: new FormControl('', {
      validators: [Validators.required],
      updateOn: 'change',
    }),
  });

  //Setting schema for work order grid
  woGridSchema = gridSchema.default.workOrderSchema.map((schema) => {
    if (schema.isClickable) {
      schema = {
        ...schema,
        onClick: this.onGridColumnClicked.bind(this),
      } as GridColumnSchema;
    }
    return schema;
  });

  //Populating list countries for dropdown
  countries$: Observable<DropDownOption[]> =
    this._globalService.countries$.pipe(
      map((response: CountryDto[] | undefined) => {
        if (!response || response.length < 1) return [];
        return response.map((country) => ({
          value: country.code,
          label: country.name,
          name: country.name,
          sublabel: country.code,
        }));
      }),
      shareReplay(1)
    );

  //Getting list of work orders based on searched container
  workOrders$: Observable<WorkOrder[]> = this.containerNumberSubject$$
    .asObservable()
    .pipe(
      switchMap((containerNumber) => {
        return this._caseService
          .customerRecoveryClaimsContainerNumberWorkOrdersGet(
            containerNumber,
            this.searchFromDateUTC,
            this.searchToDateUTC,
            '1.0'
          )
          .pipe(
            map(
              (response) =>
                response.data?.sort(
                  (wo1, wo2) =>
                    this._globalService.getTimeInMilliseconds(
                      wo2.workOrderCreatedDateTime
                    ) -
                    this._globalService.getTimeInMilliseconds(
                      wo1.workOrderCreatedDateTime
                    )
                ) ?? []
            ),
            catchError((error) => {
              if (error?.error?.detailedErrors?.errorCode == 4002) {
                this.containerNumberInput.setErrors({
                  containerNumberInValid: true,
                });
              }
              return of([]);
            })
          );
      }),
      shareReplay(1),
      tap((data) => {
        this.areWorkOrdersLoading = false;
        this.caseCreationWithoutWorkOrder = data.length === 0;
        if (this.caseCreationWithoutWorkOrder)
          this.navigateToCaseCreationWithoutWorkOrderSection();
      })
    );

  //Populating work order grid data
  workOrderGridData$: Observable<GridRowData[]> = this.workOrders$.pipe(
    map((workOrders) => {
      this.selectedWorkOrder = workOrders.find(
        (wo) => (wo.recoveryCaseNumber ?? '') == ''
      )?.workOrderNumber;
      return workOrders.reduce(
        (groupedWorkOrders: GridRowData[], currentWorkOrder: WorkOrder) => {
          const currentGridRow =
            this.generateGridCellDataForAllRowCells(currentWorkOrder);

          const matchingGroupIndex = groupedWorkOrders.findIndex(
            (x) => x.row['groupId'].value === currentWorkOrder.groupId
          );

          //If first record of the grid or If work order does not belong to the any of the previous groups, create new row.
          if (matchingGroupIndex === -1) {
            groupedWorkOrders.push({
              row: currentGridRow,
              isMasterRow: false,
              childRows: [],
              showChildRowData: false,
              isRowSelected:
                currentWorkOrder.workOrderNumber === this.selectedWorkOrder,
              hideRowSelector: !!currentWorkOrder.recoveryCaseNumber,
            });
            return groupedWorkOrders;
          }

          //If record belongs to the previous group, append it.
          if (matchingGroupIndex > -1) {
            groupedWorkOrders[matchingGroupIndex].childRows?.push(
              currentGridRow
            );
            groupedWorkOrders[matchingGroupIndex].isMasterRow = true;
            return groupedWorkOrders;
          }
          return groupedWorkOrders;
        },
        [] as GridRowData[]
      );
    })
  );

  constructor(
    private _caseService: CaseService,
    private router: Router,
    private _globalService: GlobalService,
    private _containerProtectionService: ContainerProtectService,
    private datePipe: DatePipe,
    private _commonService: CommonService
  ) {
    //Setting from date for searching work order data
    this.searchFromDateUTC.setUTCDate(
      this.searchToDateUTC.getUTCDate() - this.workOrderSearchInterval
    );
    this.searchFromDateUTC = this._globalService.changeUtcTime(
      this.searchFromDateUTC
    );
  }

  /**
   * Creates recovery case based on user inputs
   */
  async createCase() {
    const request: WorkOrder | undefined = !this.caseCreationWithoutWorkOrder
      ? await this.generateCreateCaseRequestFromWorkOrder()
      : await this.generateCreateCaseRequestFromContainerMoves();

    const response = await firstValueFrom(
      this._caseService.customerRecoveryClaimsRecoveryCasePost(request)
    );

    this.navigateToRecoveryCaseWorkFlow(
      response.data?.caseNumber ?? '',
      this.containerNumberInput.value
    );
  }

  /**
   * Generates case creation request based on selected work order from grid
   * @returns Case creation request
   */
  async generateCreateCaseRequestFromWorkOrder() {
    const workOrder = await firstValueFrom(this.workOrders$);
    const request = workOrder.find(
      (workOrder) => workOrder.workOrderNumber === this.selectedWorkOrder
    )!;
    request.assignedDateTime = new Date();
    request.assignedTo = this.userId;
    request.userId = this.userId;
    return request;
  }

  /**
   * Generates case creation request based on incident date, POD country and container move
   * @returns Case creation request
   */
  async generateCreateCaseRequestFromContainerMoves() {
    if (!this.selectedMove) {
      return;
    }

    //Extracting data from selected move
    const bolNumber = this.selectedMove.map(
      (row) => (row['bolNumber'] as GridCellData).value as string
    )[0];
    const bookingNumber = this.selectedMove.map(
      (row) => (row['bookingNumber'] as GridCellData).value as string
    )[0];
    const operatorCode = this.selectedMove.map(
      (row) => (row['operatorCode'] as GridCellData).value as string
    )[0];
    const podLocation = this.selectedMove.map(
      (row) => (row['placeOfDelivery'] as GridCellData).value as string
    )[0];
    const activityLocation = this.selectedMove.map(
      (row) => (row['activityLocation'] as GridCellData).value as string
    )[0];
    if (!bookingNumber || !podLocation || !activityLocation) {
      return;
    }

    //Populating country data
    const countries = await firstValueFrom(this._globalService.countries$);
    const shopCountry = countries.find(
      (country) =>
        country.name?.toUpperCase() === this.repairCountry?.value.toUpperCase()
    );
    // TODO: POD country code to be taken from API. Currently taking first two characters.
    const podCountry = countries.find(
      (country) => country.code === podLocation?.substring(0, 2)
    );
    // TODO: This activity location needs to be added in place of POD going further in a cleaner way.
    const activityCountry = countries.find(
      (country) => country.code === activityLocation?.substring(0, 2)
    );

    //extraction cp detail from containerProtectionList
    let filteredCPDetails = this.containerProtectionList?.filter(
      (i) => i.containerSize == this.containerProperties?.containerSize
    )[0];

    if (!filteredCPDetails) {
      filteredCPDetails = this.containerProtectionList[0];
    }
    const noCpAvaliable = this.containerProtectionList?.length > 0;

    const cpCode = noCpAvaliable ? filteredCPDetails.coverageType : '';
    const coverageCurrency = noCpAvaliable
      ? filteredCPDetails.coverageCurrency ?? 'USD'
      : 'USD';

    const containerSize = noCpAvaliable
      ? filteredCPDetails.containerSize
      : null;

    const isCpActiveCountry = noCpAvaliable ? filteredCPDetails.isActive : null;
    const localCurrency = noCpAvaliable
      ? filteredCPDetails.localCurrency
      : 'USD';
    let coverageCost = noCpAvaliable ? filteredCPDetails.coverageAmount : null;
    const date =
      this._globalService.getUtcDateFromDDMMYYYY(
        this.incidentDate?.value as string
      ) ?? new Date();

    if (coverageCurrency !== 'USD')
      coverageCost = await this.getExchangeRateForCurrency(
        coverageCost ?? 0,
        date,
        coverageCurrency
      );
    return {
      assignedDateTime: new Date(),
      assignedTo: this.userId,
      bolNumber: bolNumber,
      bookingNumber: bookingNumber,
      containerNumber: this.searchedContainerNumber,
      cpCode: cpCode,
      isCpActiveCountry: isCpActiveCountry,
      cpMasterContainerSize: containerSize,
      containerProductionYear:
        this.containerProperties?.containerManufacturingYear,
      containerSize: this.containerProperties?.containerSize,
      equipmentOwnerShipType: this.containerProperties?.ownershipType,
      equipmentSubType:
        this.containerProperties?.containerType?.containerTypeCode,
      incidentDateTime: this._globalService.getUtcDateFromDDMMYYYY(
        this.incidentDate?.value as string
      ),
      isImportReturn: podCountry?.code === shopCountry?.code,
      operatorCode: operatorCode,
      shopCountryCode: shopCountry?.code,
      shopCountryId: shopCountry?.id,
      shopCountryName: shopCountry?.name,
      // TODO: This is temporary fix to map activity country details in place of pod country. This needs to be removed.
      podCountryClusterCode:
        activityCountry?.clusterCode ?? podCountry?.clusterCode,
      podCountryClusterName:
        activityCountry?.clusterName ?? podCountry?.clusterName,
      podCountryCode: activityCountry?.code ?? podCountry?.code,
      podCountryId: activityCountry?.id ?? podCountry?.id,
      podCountryName: activityCountry?.name ?? podCountry?.name,
      userId: this.userId,
      // TODO: Hardcoding this to `USD` for now but later, we need to get CP details via an API where this value is present.
      podLocalCurrency: localCurrency,
      cpCoverageAmount: coverageCost ?? 0,
      cpCoverageCurrency: 'USD',
    } as WorkOrder;
  }

  async getExchangeRateForCurrency(
    coverageCost: number,
    date: Date,
    coverageCurrency: string
  ) {
    const exchangeRates = await lastValueFrom(
      this._commonService
        .commonCurrencyAndExchangeRatesForQuotationDateGet(
          date.toISOString(),
          this.apiVersion
        )
        .pipe(
          map((res) => {
            if (!res || !res.data) return [];
            return res.data;
          })
        )
    );
    const exchangeRateObj = exchangeRates?.filter(
      (currency) => currency.quotationCurrency == coverageCurrency
    )[0];

    if (exchangeRateObj)
      coverageCost =
        (coverageCost ?? 0) * (exchangeRateObj.exchangeRatePerUnit ?? 0);
    return coverageCost;
  }

  /**
   * Searches work order data based on entered container number
   */
  searchContainerNumber() {
    this.searchedContainerNumber = (
      this.containerNumberInput?.value as string
    ).toUpperCase();

    //Setting form control values
    this.containerNumberInput.setValue(this.searchedContainerNumber);
    this.incidentDate.reset();
    this.repairCountry.reset();
    this.containerMovesFromDate.reset();
    this.containerMovesToDate.reset();

    //Setting flags
    this.areWorkOrdersLoading = true;
    this.caseCreationWithoutWorkOrder = false;
    this.showContainerMoves = false;

    //Triggering search
    this.containerNumberSubject$$.next(this.searchedContainerNumber);
  }

  /**
   * Sets selected container move from grid
   * @param selectedMove - Selected container move
   */
  async onContainerMoveSelected(
    selectedMove: [{ [key: string]: GridCellData }]
  ) {
    this.selectedMove = selectedMove;

    if (!this.selectedMove) return;
    //Extracting data from selected move

    const bookingNumber = this.selectedMove.map(
      (row) => (row['bookingNumber'] as GridCellData).value as string
    )[0];
    const operatorCode = this.selectedMove.map(
      (row) => (row['operatorCode'] as GridCellData).value as string
    )[0];
    const podLocation = this.selectedMove.map(
      (row) => (row['placeOfDelivery'] as GridCellData).value as string
    )[0];
    const bolNumber = this.selectedMove.map(
      (row) => (row['bolNumber'] as GridCellData).value as string
    )[0];

    this.cpDetailsLoading = true;
    const res = await firstValueFrom(
      this._containerProtectionService
        .containerProtectContainersContainerNumberGet(
          this.searchedContainerNumber ?? '',
          operatorCode,
          podLocation?.substring(0, 2),
          bookingNumber,
          bolNumber
        )
        .pipe(
          map((res) => {
            this.cpDetailsLoading = false;
            if (!res || !res.data) return [];
            return res.data;
          }),
          catchError((_) => {
            this.cpDetailsLoading = false;
            return of([]);
          })
        )
    );
    this.containerProtectionList = res;
  }

  /**
   * This method does a transformation on the api response object and creates grid row object from it.
   * @param claim - Work order entity received from Web API response
   * @returns Grid row object
   */
  private generateGridCellDataForAllRowCells(claim: WorkOrder): {
    [key: string]: GridCellData;
  } {
    const claimKeyValue = claim as unknown as {
      [key: string]: unknown;
    };
    const gridRowObject: { [key: string]: GridCellData } = {};

    Object.keys(claim).map((key) => {
      gridRowObject[key] = {
        value: claimKeyValue[key],
      } as GridCellData;
    });
    return gridRowObject;
  }

  /**
   * Handling modal close event
   */
  onModelClose(_: any) {
    this.newCaseForm.reset();
    this.onModalCancel.emit(true);
  }

  /**
   * Sets selected work order number based on selected row
   * @param selectedRow - Selected work order row from grid
   */
  woSelectionChanged(selectedRow: { [key: string]: unknown }[]) {
    if (!selectedRow || selectedRow.length == 0 || selectedRow.length > 1) {
      return;
    }

    if (selectedRow && selectedRow.length == 1) {
      this.selectedWorkOrder = (
        selectedRow[0]['workOrderNumber'] as GridCellData
      ).value as number;
    }
  }

  /**
   * This method is triggered when grid column click event is triggered. We are writing various actions on this.
   * @param event - Grid Column Cell Clicked Event
   * @returns void
   */
  onGridColumnClicked(cellData: CellClickEvent) {
    if (!cellData?.column) return;
    const { column, rowData } = cellData;
    if (column === 'recoveryCaseNumber') {
      this.navigateToRecoveryCaseWorkFlow(
        rowData!['recoveryCaseNumber'].value as string,
        rowData!['containerNumber'].value as string
      );
    }
  }

  createCaseWithoutWorkOrderCheckBoxChange() {
    this.caseCreationWithoutWorkOrder = !this.caseCreationWithoutWorkOrder;
    if (this.caseCreationWithoutWorkOrder)
      this.navigateToCaseCreationWithoutWorkOrderSection();
  }

  navigateToCaseCreationWithoutWorkOrderSection() {
    setTimeout(() => {
      (
        this.containerMoveSection?.nativeElement as HTMLElement
      ).scrollIntoView();
    }, 200);
  }

  /**
   * Navigates to case workflow page based on case number and container number
   * @param caseNumber - Case number for which workflow needs to be displayed
   * @param containerNumber - Container number for which workflow needs to be displayed
   */
  private navigateToRecoveryCaseWorkFlow(
    caseNumber: string,
    containerNumber: string
  ) {
    this.router.navigate(['customer-recovery/workflow/'], {
      queryParams: {
        caseNumber: caseNumber,
        containerNumber: containerNumber,
      },
    });
  }

  /**
   * Validates if the entered repair country name is valid or not
   * Displays error message in case of invalid country name
   * @param event Event with repair country name
   */
  async validateRepairCountry(event: any) {
    const countries = await firstValueFrom(this._globalService.countries$);
    const selectedCountry = countries.find(
      (country) =>
        country.name?.toUpperCase() === event.target.value.toUpperCase()
    );
    if (!selectedCountry) {
      this.repairCountry.setErrors({});
    }
  }

  /**
   * Resets container moves grid variables
   */
  resetContainerMovesGrid() {
    this.selectedMove = undefined;
    this.showContainerMoves = false;
  }

  /**
   * Sets container properties
   * @param containerMoves Container moves along with container properties
   */
  setContainerProperties(containerMoves: ContainerMoveDto) {
    this.containerProperties = containerMoves;
  }

  /**
   * Sets container search dates based on configured interval and populates container data grid
   */
  searchContainerMovesBasedOnIncidentDate() {
    //Validating that entered date is valid and in correct format
    const incidentDateValue = this.incidentDate?.value;

    //Converting to UTC date
    var searchDate =
      this._globalService.getUtcDateFromDDMMYYYY(incidentDateValue);

    //Marking incident date control as invalid if user enters an invalid or future date
    if (!searchDate) {
      this.incidentDate.setErrors({
        dateInvalid: true,
      });
    } else {
      //Setting From Date
      searchDate.setUTCDate(
        searchDate.getUTCDate() - this.containerMovesSearchInterval
      );
      this.containerMovesFromDate.setValue(
        this.datePipe.transform(searchDate, this.dateFormat)
      );

      //Setting To Date
      searchDate.setUTCDate(
        searchDate.getUTCDate() + 2 * this.containerMovesSearchInterval
      );

      //If To Date is greater then Current Date, then Current Date will be used
      if (searchDate < new Date()) {
        this.containerMovesToDate.setValue(
          this.datePipe.transform(searchDate, this.dateFormat)
        );
      } else {
        this.containerMovesToDate.setValue(
          this.datePipe.transform(new Date(), this.dateFormat)
        );
      }

      //Displaying grid
      this.showContainerMoves = true;
    }
  }

  /**
   * Validates if From Date is valid or not based on current date and To Date
   * If From Date is valid, then errors are cleared from date input fields
   */
  validateAndSetFromDate() {
    const toDate =
      this._globalService.getUtcDateFromDDMMYYYY(
        this.containerMovesToDate?.value
      ) ?? new Date();
    const fromDate = this._globalService.getUtcDateFromDDMMYYYY(
      this.containerMovesFromDate?.value,
      toDate
    );
    if (!fromDate) {
      this.containerMovesFromDate.setErrors({
        dateInvalid: true,
      });
    } else {
      this.movesFromDate = fromDate.toISOString().substring(0, 10);
      this.containerMovesToDate.setErrors(null);
    }
  }

  /**
   * Validates if To Date is valid or not based on current date and From Date
   * If To Date is valid, then errors are cleared from date input fields
   */
  validateAndSetToDate() {
    const toDate = this._globalService.getUtcDateFromDDMMYYYY(
      this.containerMovesToDate?.value
    );
    if (!toDate) {
      this.containerMovesToDate.setErrors({
        dateInvalid: true,
      });
    } else {
      const fromDate = this._globalService.getUtcDateFromDDMMYYYY(
        this.containerMovesFromDate?.value
      );
      if (fromDate && toDate < fromDate) {
        this.containerMovesToDate.setErrors({
          dateInvalid: true,
        });
      } else {
        this.movesToDate = toDate.toISOString().substring(0, 10);
        this.containerMovesFromDate.setErrors(null);
      }
    }
  }
}
