import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  Input,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  GridColumnSchema,
  LibFormComponent,
  PanelComponent,
  SectionSkeletonLoaderComponent,
  Toast,
  ToasterService,
} from '@maersk-global/angular-shared-library';
import { TemplateModel } from '@maersk-global/angular-shared-library/lib/models/template-model';
import { gcssBookingInfo } from '../../../common/models/gcssBookingInfo';
import { AppWorkflowContainerMovesComponent } from '../app-workflow-container-moves/app-workflow-container-moves.component';
import { GlobalService } from '../../../global-service';
import {
  Observable,
  catchError,
  combineLatest,
  lastValueFrom,
  map,
  of,
  tap,
} from 'rxjs';
import { CustomerRecoveryCaseDto } from '../../../common/models/customerRecoveryCaseDto';
import { CustomerRecoveryClaimService } from '../../../common/services/customer-recovery/customer-recovery-claim.service';
import { FIELD_NAME } from '../../../common/constants/app.constants';
import { HttpStatusCode } from '../../../common/models/httpStatusCode';

@Component({
  selector: 'app-workflow-booking-info',
  standalone: true,
  imports: [
    LibFormComponent,
    CommonModule,
    AppWorkflowContainerMovesComponent,
    PanelComponent,
    SectionSkeletonLoaderComponent,
  ],
  templateUrl: './app-workflow-booking-info.component.html',
  styleUrl: './app-workflow-booking-info.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppWorkflowBookingInfoComponent implements OnInit {
  @Input() item!: TemplateModel;
  @Input() itemValue!: gcssBookingInfo | null;
  @Input() disable?: boolean | false;
  items: TemplateModel[] = [];
  containerMovesSchema: GridColumnSchema[] = [];
  showContainerMoves: boolean = false;
  containerMovesItem!: TemplateModel;
  recoveryData!: CustomerRecoveryCaseDto;
  filterFromDate: string | undefined;
  filterToDate: string | undefined;
  containerMovesFromDate: string | undefined;
  containerMovesToDate: string | undefined;
  containerNumber: string | undefined;
  selectedFromDate: string | undefined;
  selectedToDate: string | undefined;
  caseNumber: string | undefined;
  updateBookingNumberModal: boolean = false;
  newBookingNo: string = '';
  bookingNumberErrorMsg: string = '';

  customerRecoveryData$ = this.globalService.customerRecoveryData$.pipe(
    tap((recoveryData) => {
      this.recoveryData = recoveryData;
      this.containerNumber = recoveryData.containerNumber;
      this.caseNumber = recoveryData.recoveryCaseNumber;
      if (!this.selectedFromDate || !this.selectedToDate)
        this.setDateRangeForContainerMoves();
    })
  );

  cargoDetail$: Observable<gcssBookingInfo> =
    this.globalService.bookingCargoDetails$.pipe(
      tap((bookingInfo) => this.bindValueToItems(bookingInfo))
    );

  combinedData$ = combineLatest([
    this.cargoDetail$,
    this.customerRecoveryData$,
  ]).pipe(map(([cargo, customerRecovery]) => ({ cargo, customerRecovery })));

  invalidDateSelected: boolean = false;

  constructor(
    private globalService: GlobalService,
    private customerRecoveryService: CustomerRecoveryClaimService,
    private toaster: ToasterService
  ) {}

  ngOnInit() {
    this.items = this.item.items as TemplateModel[];
    const bookingItem = this.items.filter((i) => i.name == 'bookingNumber')[0];

    if ('icon' in bookingItem) {
      bookingItem.icon = {
        icon: 'pencil',
        onClick: this.bookingNumberModalOpen.bind(this),
        isClickable: true,
      };
    }
  }

  /*
   * Repopulates the value  on the changes.
   * @param changes When async itemValue  are received from Async calls
   */
  ngOnChanges(changes: SimpleChanges): void {}

  bookingChangeOnTextChange(text: Event) {
    this.newBookingNo = ((text as InputEvent).target as HTMLInputElement).value;
  }

  bookingNumberModalOpen() {
    this.updateBookingNumberModal = true;
  }

  async updateBookingNumberConfirm() {
    if (this.newBookingNo)
      await lastValueFrom(
        this.customerRecoveryService
          .customerRecoveryClaimsBookingNumberUpdatePost(
            this.caseNumber ?? '',
            this.newBookingNo,
            '1.0'
          )
          .pipe(
            map((response) => {
              if (response.statusCode === 'OK') {
                this.BookingNumberUpdateSuccessful();
                return;
              }
              if (response.statusCode === 'BadRequest') {
                this.bookingNumberErrorMsg =
                  'Booking Number not linked with the container';
              }
            }),
            catchError((error) => {
              this.bookingNumberErrorMsg = 'Failed updating Booking Number';
              return of(false);
            })
          )
      );
  }

  BookingNumberUpdateSuccessful() {
    this.recoveryData.bookingNumber = this.newBookingNo;
    this.globalService.updateCustomerRecoveryData(this.recoveryData);
    this.globalService.updateBookingNumber(this.newBookingNo);
    this.updateBookingNumberModal = false;
    this.toaster.showToast({
      message: 'Booking Number updated!',
      type: 'success',
    } as Toast);
  }

  bindValueToItems(bookingValues: gcssBookingInfo) {
    if (bookingValues)
      this.items.forEach((item: TemplateModel) => {
        item.disabled = this.disable;
        const bookingCargoKeyValue = bookingValues as {
          [key: string]: unknown;
        };

        if (item.name == FIELD_NAME.SERVICE_RECEIVE_DELIVERY) {
          item.value =
            bookingValues.importServiceMode +
            '/' +
            bookingValues.exportServiceMode;
        } else if (item.name == FIELD_NAME.EQUIPMENT_NUMBER) {
          item.value = this.containerNumber;
        } else if (item.name == FIELD_NAME.COVERAGE_AMOUNT) {
          item.value =
            this.recoveryData.cpCoverageCurrency?.toUpperCase() == 'USD'
              ? '$' + (this.recoveryData.cpCoverageAmount ?? 0)
              : this.recoveryData.cpCoverageCurrency +
                ' ' +
                (this.recoveryData.cpCoverageAmount ?? 0);
        } else if (item.name == FIELD_NAME.CP_PLAN) {
          item.value = this.recoveryData.cpPurchased ?? '-';
        } else if (item.name == FIELD_NAME.ABOVE_RECOVERABLE_COST) {
          item.value =
            '$' +
            (this.formatDecimal(this.recoveryData.aboveCoverageCostUSD) ?? 0);
        } else if (item.name == FIELD_NAME.RECOVERABLE_COST_USD) {
          item.value =
            '$' +
            (this.formatDecimal(this.recoveryData.recoverableCostUSD) ?? 0);
        } else {
          item.value = bookingCargoKeyValue[item.name as string];
        }

        if (!item.value) item.value = '-';
      });
  }

  setDateRangeForContainerMoves() {
    if (this.recoveryData.workOrderDate) {
      const workOrderDate = new Date(this.recoveryData.workOrderDate);
      const toDate = new Date(workOrderDate.getTime());
      const fromDate = new Date(workOrderDate.getTime());

      toDate.setDate(toDate.getDate() + 10);
      fromDate.setDate(fromDate.getDate() - 30);

      const formatDateOptions = {
        timeZone: 'UTC',
      };

      this.selectedFromDate = fromDate.toLocaleDateString(
        'en-GB',
        formatDateOptions
      );
      this.selectedToDate = toDate.toLocaleDateString(
        'en-GB',
        formatDateOptions
      );

      this.containerMovesFromDate = this.selectedFromDate;
      this.containerMovesToDate = this.selectedToDate;
    }
  }

  OnDateSelectHandler(type: string, event: any) {
    if (type == 'from') {
      this.selectedFromDate = event.target.value;
    } else {
      this.selectedToDate = event.target.value;
    }
  }

  updateMoves() {
    const isValidDates =
      this.isValidDateFormat(this.selectedFromDate!) &&
      this.isValidDateFormat(this.selectedToDate!);

    if (isValidDates) {
      this.containerMovesFromDate = this.selectedFromDate;
      this.containerMovesToDate = this.selectedToDate;
    }

    this.invalidDateSelected = !isValidDates;
  }

  showHideContainerMovesLink(): void {
    this.showContainerMoves = !this.showContainerMoves;

    if (this.item && this.item.items) {
      this.containerMovesItem = this.item.items.filter(
        (o) => o.type === 'containerMoves'
      )[0];
    }
  }

  private isValidDateFormat(dateString: string): boolean {
    // Regular expression for dd/mm/yyyy format
    const regex = /^\d{2}\/\d{2}\/\d{4}$/;

    // Check if the input string matches the regex
    const isValid = regex.test(dateString);

    return isValid;
  }

  private formatDecimal(value: number | undefined): number {
    if (!value) return 0;
    return Math.round(value * 100) / 100;
  }
}
